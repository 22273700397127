<template>
  <div class="app-container">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div
          class="
            col-7
            p-0
            align-self-center
            font-weight-bold
            d-flex
            align-items-center
          "
        >
          <h5 class="d-inline mr-2 font-weight-bold">
            {{ $t("message.suppliers_aggrement") }}
          </h5>
          <crm-refresh @c-click="refresh()"></crm-refresh>
          <div class="text-center d-flex sorddata ml-3">
            <el-input
              class="ml-3"
              size="mini"
              :placeholder="$t('message.search')"
              prefix-icon="el-icon-search"
              v-model="filterForm.search"
              clearable
              
            ></el-input>
            <el-date-picker
              class="ml-3"
              size="mini"
              v-model="filterForm.start_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.start_date')"
            ></el-date-picker>
            <el-date-picker
              class="ml-3"
              size="mini"
              v-model="filterForm.end_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.end_date')"
            ></el-date-picker>
          </div>
        </div>
        <div
          class="
            col-5
            align-items-center align-self-center
            text-right
            pr-0
            d-flex
            justify-content-end
          "
        >
          <el-button
            class="mr-2"
            size="mini"
            @click="drawer.create.status = true"
            icon="el-icon-circle-plus-outline"
            >{{ $t("message.create") }}</el-button
          >
          <crm-column-settings
            :columns="columns"
            :modelName="'supplierContracts'"
            @c-change="updateColumn"
          ></crm-column-settings>
        </div>
      </div>
      <table
        class="table table-bordered table-hover mr-0 ml-0 p-0 bg-white"
        v-loading="loadingData"
      >
        <crm-pagination
          :pagination="pagination"
          @c-change="updatePagination"
        ></crm-pagination>
        <thead>
          <tr>
            <crm-th
              :column="columns.id"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.number"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.begin_date"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.supplier_id"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.status_id"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.sum"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.description"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.created_at"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.updated_at"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.settings"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
          </tr>

          <tr>
            <th v-if="columns.id.show">
              <el-input
                clearable
                size="mini"
                class="id_input"
                v-model="filterForm.id"
                :placeholder="columns.id.title"
              ></el-input>
            </th>
            <th v-if="columns.number.show">
              <el-input
                clearable
                size="mini"
                class="id_input"
                v-model="filterForm.number"
                :placeholder="columns.number.title"
              ></el-input>
            </th>
            <th v-if="columns.begin_date.show">
              <el-date-picker
                size="mini"
                v-model="filterForm.begin_date"
                :placeholder="columns.begin_date.title"
                type="date"
                :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </th>
            <th v-if="columns.supplier_id.show">
              <el-select
                filterable
                clearable
                :placeholder="columns.supplier_id.title"
                size="mini"
                v-model="filterForm.supplier_id"
              >
                <el-option
                  v-for="item in suppliers"
                  :key="item.name + item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </th>
            <th v-if="columns.status_id.show">
              <el-select
                filterable
                clearable
                :placeholder="columns.status_id.title"
                size="mini"
                v-model="filterForm.status_id"
              >
                <el-option
                  v-for="item in statuses"
                  :key="item.title + item.id"
                  :label="item.title"
                  :value="item.id"
                ></el-option>
              </el-select>
            </th>
            <th v-if="columns.sum.show">
              <el-input
                clearable
                size="mini"
                class="id_input"
                v-model="filterForm.sum"
                :placeholder="columns.sum.title"
              ></el-input>
            </th>
            <th v-if="columns.description.show">
              <el-input
                clearable
                size="mini"
                class="id_input"
                v-model="filterForm.description"
                :placeholder="columns.description.title"
              ></el-input>
            </th>
            <th v-if="columns.created_at.show">
              <el-date-picker
                size="mini"
                v-model="filterForm.created_at"
                :placeholder="columns.created_at.title"
                type="date"
                :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </th>
            <th v-if="columns.updated_at.show">
              <el-date-picker
                size="mini"
                v-model="filterForm.updated_at"
                :placeholder="columns.updated_at.title"
                type="date"
                :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </th>
            <th v-if="columns.settings.show"></th>
          </tr>
        </thead>
        <transition-group name="flip-list" tag="tbody">
          <tr
            v-for="contract in list"
            :key="contract.id"
            class="cursor-pointer"
          >
            <td v-if="columns.id.show">{{ contract.id }}</td>
            <td v-if="columns.number.show">{{ contract.number }}</td>
            <td v-if="columns.begin_date.show">{{ contract.begin_date }}</td>
            <td v-if="columns.supplier_id.show">
              {{ getSupplierName(contract.supplier_id) }}
            </td>
            <td v-if="columns.status_id.show">
              {{ getStatusName(contract.status_id) }}
            </td>
            <td v-if="columns.sum.show">{{ contract.sum }}</td>
            <td v-if="columns.description.show">{{ contract.description }}</td>
            <td v-if="columns.created_at.show">
              {{ contract.created_at  }}
            </td>
            <td v-if="columns.updated_at.show">
              {{ contract.updated_at  }}
            </td>
            <td v-if="columns.settings.show" class="settings-td">
              <crm-setting-dropdown
                :model="contract"
                name="contracts"
                :actions="actions"
                @edit="edit"
                @delete="destroy"
              ></crm-setting-dropdown>
            </td>
          </tr>
        </transition-group>
      </table>
    </div>

    <el-drawer
      size="85%"
      :visible.sync="drawer.create.status"
      :with-header="false"
      :ref="drawer.create.name"
      :before-close="beforeClose"
    >
      <CrmCreate :drawer-name="drawer.create.name" />
    </el-drawer>
  </div>
</template>
<script>
import CrmCreate from "./components/crm-create";
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";

export default {
  name: "contractWarehouses",
  mixins: [list, drawer],
  data() {
    return {
      loadingData: false,
      drawer: {
        create: {
          name: "create",
          status: false,
        },
      },
    };
  },
  components: {
    CrmCreate,
  },
  async mounted() {
    if (this.suppliers && this.suppliers.length === 0)
      await this.loadSuppliers();
    if (this.statuses && this.statuses.length === 0) await this.loadStatuses();
  },
  computed: {
    ...mapGetters({
      list: "supplierContracts/list",
      columns: "supplierContracts/columns",
      pagination: "supplierContracts/pagination",
      filter: "supplierContracts/filter",
      sort: "supplierContracts/sort",
      suppliers: "suppliers/inventory",
      statuses: "statuses/inventory",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "supplierContracts/index",
      updateSort: "supplierContracts/updateSort",
      updateFilter: "supplierContracts/updateFilter",
      updateColumn: "supplierContracts/updateColumn",
      updatePagination: "supplierContracts/updatePagination",
      editModel: "supplierContracts/show",
      empty: "supplierContracts/empty",
      delete: "supplierContracts/destroy",
      refreshData: "supplierContracts/refreshData",
      loadSuppliers: "suppliers/inventory",
      loadStatuses: "statuses/inventory",
    }),
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            console.log(err);
            this.loadingData = false;
          });
      }
    },
    getSupplierName(id) {
      const data = this.suppliers.find((supplier) => {
        return supplier.id == id;
      });
      return data ? data.name : "";
    },
    getStatusName(id) {
      const data = this.statuses.find((status) => {
        return status.id == id;
      });
      return data ? data.title : "";
    },
    beforeClose(done) {
      this.emptyModel();
      this.refreshData();
      done();
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    async edit(model) {
      await this.editModel(model.id)
        .then(async (res) => {

          this.drawer.create.status = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$alert(res);
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
